import { Bell, X } from "lucide-react";
import { useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useSWRInfinite from "swr/infinite";

import { fetchMarkReadNotifications } from "@/components/common/notifications/api";
import { NotificationInterface } from "@/components/common/notifications/interface";
import { NotificationContent } from "@/components/common/notifications/notification-content";
import { Loading } from "@/components/loading";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { cn, superFetchData } from "@/lib/utils";

const PAGE_SIZE = 10;

export function Notifications() {
  const [open, setOpen] = useState(false);

  const getKey = (pageIndex: number, previousPageData: any) => {
    const params = new URLSearchParams();
    // pagination
    params.append("page", String(pageIndex));
    params.append("limit", String(PAGE_SIZE));

    if (previousPageData && !previousPageData.notifications) return null; // reached the end
    return `/users/notifications?${params.toString()}`; // SWR key
  };

  const {
    data = [],
    size,
    setSize,
    isLoading,
    error,
    mutate,
  } = useSWRInfinite(getKey, superFetchData, {
    revalidateIfStale: true,
  });

  const hasNextPage =
    data && data[0]?.pagination?.total > data.length * PAGE_SIZE;

  const total_unread = data[0]?.total_unread || 0;

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: () => setSize(size + 1),
    disabled: !!error,
    rootMargin: "0px 0px 300px 0px",
  });

  function handleOpenChange(open: boolean) {
    setOpen(open);
    if (open) {
      fetchMarkReadNotifications();
    } else {
      mutate();
    }
  }

  return (
    <>
      <Popover open={open} onOpenChange={handleOpenChange}>
        <PopoverTrigger asChild>
          <Button
            variant="secondary"
            className={cn(
              "relative h-[53px] w-14 hover:text-title",
              open &&
                "bg-emerald-900 text-interface-white hover:text-interface-white",
            )}
            aria-label="notifications"
          >
            <Bell strokeWidth={1} size={24} />
            {total_unread > 0 && (
              <Badge
                variant="secondary"
                className={cn(
                  "absolute right-3 top-2 h-[16px] rounded-full bg-error px-[5px] text-[8px] text-gold-50",
                )}
              >
                {total_unread}
              </Badge>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="relative top-0 h-full w-screen p-0 px-6 py-4 shadow-[0_39px_85px_0_rgba(35,45,42,0.15)] md:min-h-[500px] md:w-[600px]">
          <div className="flex items-center justify-between">
            <h6 className="text-emerald-black">Notifications</h6>
            <X
              onClick={() => setOpen(false)}
              className="cursor-pointer"
              aria-label="close"
            />
          </div>
          <Separator className="my-4 bg-coffee-200" />
          <div ref={rootRef}>
            <div className="h-full max-h-[500px] space-y-4 overflow-y-auto">
              {data.map(({ notifications }) =>
                notifications.map((noti: NotificationInterface) => (
                  <NotificationContent key={noti.id} noti={noti} />
                )),
              )}
              {hasNextPage && (
                <div ref={sentryRef}>
                  <Loading />
                </div>
              )}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
}
