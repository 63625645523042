import { FileClock, MessageSquare } from "lucide-react";
import { CircleDollarSign } from "lucide-react";
import Link from "next/link";

import { NotificationInterface } from "@/components/common/notifications/interface";
import { cn } from "@/lib/utils";
import dayjs from "@/packages/dayjs";

export function NotificationContent({ noti }: { noti: NotificationInterface }) {
  return (
    <div
      className={cn(
        "flex items-center justify-between p-4",
        noti.is_read ? "bg-coffee-50" : "bg-emerald-10",
      )}
      key={noti.id}
    >
      <div className="flex w-full items-start space-x-4 rounded-lg">
        <NotiIcon type={noti.type} isRead={noti.is_read} />
        <div className="flex-1">
          <h3 className="p1-bold text-sm text-title">{noti.short_message}</h3>
          <p className="p4 mt-1 text-sm text-emerald-black">
            <Content type={noti.type} extra={noti.extra} />
          </p>
          <Link className="b5 mt-1" href={noti.cta_url} target="_blank">
            {noti.cta_text}
          </Link>
        </div>
        <div className="flex flex-col items-end gap-1">
          {!noti.is_read && (
            <div className="h-[14px] w-[14px] rounded-full bg-emerald-400" />
          )}
          <div className="p4-medium-mobile">
            {dayjs(noti.created_at).fromNow()}
          </div>
        </div>
      </div>
    </div>
  );
}

function Content({ type, extra }: any) {
  switch (type) {
    case "booking_created_lab":
      return (
        <div className="p4">
          Congratulations! You have a new booking request placed by{" "}
          <span className="p1-bold text-sm">
            {extra.user_first_name + " " + extra.user_last_name}
          </span>{" "}
          for{" "}
          <span className="p1-bold text-sm">
            {extra.booking_items?.[0]?.name}
          </span>
          .
        </div>
      );
    case "booking_created_research":
      return (
        <div className="p4">
          We&apos;ve received your booking request{" "}
          <span className="p1-bold text-sm">#{extra.code}</span> for the{" "}
          <span className="p1-bold text-sm">
            {extra.booking_items?.[0]?.name}
          </span>{" "}
          at <span className="p1-bold text-sm">{extra.supply_org_name}</span>{" "}
          Lab. It&apos;s now pending approve--thanks for your patience!
        </div>
      );
    case "unread_chat_messages":
      return (
        <div className="p4">
          You have unread messages from{" "}
          <span className="p1-bold text-sm">{extra?.sender}</span>.
        </div>
      );
    case "booking_approved_research":
      return (
        <div className="p4">
          Congratulations! Your booking request{" "}
          <span className="p1-bold text-sm">#{extra.code}</span> at{" "}
          <span className="p1-bold text-sm">{extra.supply_org_name}</span> Lab
          has been approved. Please review booking details to make sure you
          fulfill all lab requirements prior to your booking time.
        </div>
      );
    case "booking_rejected_research":
      return (
        <div className="p4">
          Your booking <span className="p1-bold text-sm">#{extra.code}</span> at{" "}
          <span className="p1-bold text-sm">{extra.supply_org_name}</span> Lab
          has been rejected.
        </div>
      );
    case "booking_payment_success_research":
      return (
        <div className="p4">
          Your payment for booking{" "}
          <span className="p1-bold text-sm">#{extra.code}</span> at{" "}
          <span className="p1-bold text-sm">{extra.supply_org_name}</span> Lab
          has been processed successfully.
        </div>
      );
    case "booking_payment_success_lab":
      return (
        <div className="p4">
          Your payment request for booking{" "}
          <span className="p1-bold text-sm">#{extra.code}</span> for{" "}
          <span className="p1-bold text-sm">
            {extra.booking_items?.[0]?.name}
          </span>{" "}
          was paid by{" "}
          <span className="p1-bold text-sm">
            {extra.user_first_name + " " + extra.user_last_name}
          </span>
          . You can view the details below.
        </div>
      );
    case "booking_payment_request_created_research":
      return (
        <div className="p4">
          <span className="p1-bold text-sm">{extra.supply_org_name}</span> has
          issued a payment request for your booking{" "}
          <span className="p1-bold text-sm">#{extra.code}</span> of the{" "}
          <span className="p1-bold text-sm">
            {extra.booking_items?.[0]?.name}
          </span>
          . Please review the details and proceed with payment at your earliest
          convenience.
        </div>
      );
    case "remind_review_booking_research":
      return (
        <div className="p4">
          Don&apos;t forget to rate your experience! Your review help us improve
          our services and ensure a smoother experience for the future.
        </div>
      );
    case "remind_review_booking_lab":
      return (
        <div className="p4">
          Don&apos;t forget to rate your experience! Your review help us improve
          our services and ensure a smoother experience for the future.
        </div>
      );
    default:
      return null;
  }
}

function NotiIcon({ type, isRead }: { type: string; isRead: boolean }) {
  let icon = null;
  switch (type) {
    case "booking_created_lab":
    case "booking_created_research":
    case "booking_approved_research":
    case "booking_rejected_research":
      icon = <FileClock size={20} strokeWidth={1} />;
      break;
    case "booking_payment_created_lab":
    case "booking_payment_request_created_research":
      icon = <CircleDollarSign size={20} strokeWidth={1} />;
      break;
    case "booking_payment_success_research":
    case "booking_payment_success_lab":
      icon = <CircleDollarSign size={20} strokeWidth={1} />;
      break;
    case "unread_chat_messages":
      icon = <MessageSquare size={20} strokeWidth={1} />;
      break;
  }
  return (
    <div
      className={cn(
        "flex h-10 w-10 items-center justify-center rounded-full",
        isRead
          ? "bg-interface-white text-coffee-500"
          : "bg-emerald-50 text-emerald-400",
      )}
    >
      {icon}
    </div>
  );
}
